<template>
  <es-table class="sc-analytics" :headers="headers" :items="analytics" date-filter download="analytics.export"
            @downloadData="downloadData" @update="getIntegrationsAnalytics" :importFormats="importFormats">
    <div class="d-flex align-center">
      <h3 class="mr-7">Аналитика по каналам продаж</h3>
      <EsFastDatesRange />
    </div>
  </es-table>
</template>

<script>
import {mapActions, mapState} from "vuex";
import exportData from '@/utils/files/requestExport';
import EsFastDatesRange from "@/components/сommon/EsTable/EsFastDatesRange";

export default {
  name: "SalesChannelAnalytics",
  components: {EsFastDatesRange},
  data: () => ({
    importFormats: ['xlsx'],
    headers: [
      {
        text: 'Источник трафика',
        value: 'int.name',
      },
      {
        text: 'Конверсия',
        value: 'conversion',
      },
      {
        text: 'Количество клиентов всего',
        value: 'clients_count',
      },
      {
        text: 'Количество клиентов сегодня',
        value: 'today_clients_count',
      },
      {
        text: 'Количество клиентов за месяц',
        value: 'current_month_clients_count',
      },
      {
        text: 'Учитываемые клиенты в конверсии',
        value: 'processed_clients_count',
      },
      {
        text: 'Не учитываемые клиенты в конверсии',
        value: 'unprocessed_clients_count',
      },
      {
        text: 'Количество сделок',
        value: 'deals_count',
      },
      {
        text: 'Средний чек',
        value: 'average_check',
      },
      {
        text: 'Выручка',
        value: 'sales_amount',
      },
    ],
  }),
  computed: {
    ...mapState("analytics", ["analytics",]),
  },
  methods: {
    ...mapActions({
      getIntegrationsAnalytics: 'analytics/getIntegrationsAnalytics',
    }),
    downloadData(payload) {
      exportData(this.$store.dispatch, 'analytics/downloadReport', {
        name: "sale-channels",
        args: payload
      }, `Аналитика по каналам продаж ${this.$moment(new Date()).format('DD-MM-YYYY')}`);
    },
  },
}
</script>

<style lang="scss" scoped>

.sc-analytics h3 {
  color: #ECA961;
}
</style>
